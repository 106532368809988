<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row>
      <b-col sm="12" md="4" lg="4">
        <div class="bracket">
          <h4>Treasury Crypto Account 1</h4>
          <h6>market@treasury.id</h6>

          <table class="bracket-item">
            <tr>
              <td>BIDR</td>
              <td>:</td>
              <td>{{ this.first_account.bidr }}</td>
            </tr>
            <tr>
              <td>Bitcoin</td>
              <td>:</td>
              <td>{{ this.first_account.btc }} BTC</td>
            </tr>
            <tr>
              <td>Ethereum</td>
              <td>:</td>
              <td>{{ this.first_account.eth }} ETH</td>
            </tr>
            <tr>
              <td>USD Tether</td>
              <td>:</td>
              <td>{{ this.first_account.usdt }} USDT</td>
            </tr>
            <tr>
              <td>Binance Coin</td>
              <td>:</td>
              <td>{{ this.first_account.bnb }} BNB</td>
            </tr>
            <tr>
              <td>Tokocrypto Coin</td>
              <td>:</td>
              <td>{{ this.first_account.tko }} TKO</td>
            </tr>
            <tr>
              <td>Cardano</td>
              <td>:</td>
              <td>{{ this.first_account.ada }} ADA</td>
            </tr>
            <tr>
              <td>Algorand</td>
              <td>:</td>
              <td>{{ this.first_account.algo }} ALGO</td>
            </tr>
            <tr>
              <td>Doge</td>
              <td>:</td>
              <td>{{ this.first_account.doge }} DOGE</td>
            </tr>
            <tr>
              <td>Polkadot</td>
              <td>:</td>
              <td>{{ this.first_account.dot }} DOT</td>
            </tr>
            <tr>
              <td>Fantom</td>
              <td>:</td>
              <td>{{ this.first_account.ftm }} FTM</td>
            </tr>
            <tr>
              <td>Polygon</td>
              <td>:</td>
              <td>{{ this.first_account.matic }} MATIC</td>
            </tr>
            <tr>
              <td>Solana</td>
              <td>:</td>
              <td>{{ this.first_account.sol }} SOL</td>
            </tr>
            <tr>
              <td>Ripple</td>
              <td>:</td>
              <td>{{ this.first_account.xrp }} XRP</td>
            </tr>
            <tr>
              <td>Zilliqa</td>
              <td>:</td>
              <td>{{ this.first_account.zil }} ZIL</td>
            </tr>
          </table>
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <div class="bracket">
          <h4>Treasury Crypto Account 2</h4>
          <h6>partnership@treasury.id</h6>

          <table class="bracket-item">
            <tr>
              <td>BIDR</td>
              <td>:</td>
              <td>{{ this.second_account.bidr }}</td>
            </tr>
            <tr>
              <td>Bitcoin</td>
              <td>:</td>
              <td>{{ this.second_account.btc }} BTC</td>
            </tr>
            <tr>
              <td>Ethereum</td>
              <td>:</td>
              <td>{{ this.second_account.eth }} ETH</td>
            </tr>
            <tr>
              <td>USD Tether</td>
              <td>:</td>
              <td>{{ this.second_account.usdt }} USDT</td>
            </tr>
            <tr>
              <td>Binance Coin</td>
              <td>:</td>
              <td>{{ this.second_account.bnb }} BNB</td>
            </tr>
            <tr>
              <td>Tokocrypto Coin</td>
              <td>:</td>
              <td>{{ this.second_account.tko }} TKO</td>
            </tr>
            <tr>
              <td>Cardano</td>
              <td>:</td>
              <td>{{ this.second_account.ada }} ADA</td>
            </tr>
            <tr>
              <td>Algorand</td>
              <td>:</td>
              <td>{{ this.second_account.algo }} ALGO</td>
            </tr>
            <tr>
              <td>Doge</td>
              <td>:</td>
              <td>{{ this.second_account.doge }} DOGE</td>
            </tr>
            <tr>
              <td>Polkadot</td>
              <td>:</td>
              <td>{{ this.second_account.dot }} DOT</td>
            </tr>
            <tr>
              <td>Fantom</td>
              <td>:</td>
              <td>{{ this.second_account.ftm }} FTM</td>
            </tr>
            <tr>
              <td>Polygon</td>
              <td>:</td>
              <td>{{ this.second_account.matic }} MATIC</td>
            </tr>
            <tr>
              <td>Solana</td>
              <td>:</td>
              <td>{{ this.second_account.sol }} SOL</td>
            </tr>
            <tr>
              <td>Ripple</td>
              <td>:</td>
              <td>{{ this.second_account.xrp }} XRP</td>
            </tr>
            <tr>
              <td>Zilliqa</td>
              <td>:</td>
              <td>{{ this.second_account.zil }} ZIL</td>
            </tr>
          </table>
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <div class="bracket">
          <h4>Saldo Seluruh User</h4>
          <h6 style="visibility:hidden">market@treasury.id</h6>

          <table class="bracket-item">
            <tr>
              <td>BIDR</td>
              <td>:</td>
              <td>{{ this.balance | currency }}</td>
            </tr>
            <tr>
              <td>Bitcoin</td>
              <td>:</td>
              <td>{{ this.globalBalance.btc }} BTC</td>
            </tr>
            <tr>
              <td>Ethereum</td>
              <td>:</td>
              <td>{{ this.globalBalance.eth }} ETH</td>
            </tr>
            <tr>
              <td>USD Tether</td>
              <td>:</td>
              <td>{{ this.globalBalance.usdt }} USDT</td>
            </tr>
            <tr>
              <td>Binance Coin</td>
              <td>:</td>
              <td>{{ this.globalBalance.bnb }} BNB</td>
            </tr>
            <tr>
              <td>Tokocrypto Coin</td>
              <td>:</td>
              <td>{{ this.globalBalance.tko }} TKO</td>
            </tr>
            <tr>
              <td>Cardano</td>
              <td>:</td>
              <td>{{ this.globalBalance.ada }} ADA</td>
            </tr>
            <tr>
              <td>Algorand</td>
              <td>:</td>
              <td>{{ this.globalBalance.algo }} ALGO</td>
            </tr>
            <tr>
              <td>Doge</td>
              <td>:</td>
              <td>{{ this.globalBalance.doge }} DOGE</td>
            </tr>
            <tr>
              <td>Polkadot</td>
              <td>:</td>
              <td>{{ this.globalBalance.dot }} DOT</td>
            </tr>
            <tr>
              <td>Fantom</td>
              <td>:</td>
              <td>{{ this.globalBalance.ftm }} FTM</td>
            </tr>
            <tr>
              <td>Polygon</td>
              <td>:</td>
              <td>{{ this.globalBalance.matic }} MATIC</td>
            </tr>
            <tr>
              <td>Solana</td>
              <td>:</td>
              <td>{{ this.globalBalance.sol }} SOL</td>
            </tr>
            <tr>
              <td>Ripple</td>
              <td>:</td>
              <td>{{ this.globalBalance.xrp }} XRP</td>
            </tr>
            <tr>
              <td>Zilliqa</td>
              <td>:</td>
              <td>{{ this.globalBalance.zil }} ZIL</td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
    <b-row align-h="around" class="m-5">
      <b-col>
        <div class="text-center">
          <b-button variant="success" class="bracket-action" size="lg" @click="openTopup" type="button">Top Up</b-button>
        </div>
      </b-col>
      <b-col>
        <div class="text-center">
          <b-button variant="secondary" class="bracket-action" size="lg" @click="openWithdraw" type="button" disabled>Withdraw Balance</b-button>
        </div>
      </b-col>
    </b-row>
    <b-card-header v-if="this.topup === true">
      Top Up Balance
    </b-card-header>
    <b-card-body v-if="this.topup === true">
      <b-form-group id="account"
        label="Account"
        label-for="account">
        <b-form-select id="account"
          :plain="true"
          v-model.lazy.trim="$v.form.account.$model"
          :state="chkState('account')"
          aria-describedby="accountFeedback"
          autocomplete='given-name'
          autofocus
          :options="accountTopup">
        </b-form-select>
        <b-form-invalid-feedback id="accountFeedback">
          <span v-if="!$v.form.account.required">- Account tidak boleh kosong!</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="amount"
        label="Amount IDR Top Up"
        label-for="amount">
        <b-form-input id="amount"
          type="number"
          v-model.lazy.trim="$v.form.amount.$model"
          :state="chkState('amount')"
          aria-describedby="amountFeedback"
          placeholder="0"
          autocomplete='given-name'
          autofocus />
        <b-form-invalid-feedback id="amountFeedback" >
          <span v-if="!$v.form.amount.required">- Amount tidak boleh kosong!</span><br>
          <span v-if="!$v.form.amount.numeric">- Amount harus berupa angka valid.</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col sm="12" md="10" lg="10">
          <b-form-group id="otp" label="Insert OTP Code" label-for="otp_code">
            <b-input-group class="mt-3" id="otp Code">
              <b-form-input placeholder="OTP Code" v-model="form.otp"></b-form-input>
              <b-input-group-append>
                <b-button variant="secondary" :disabled="sended" @click="sendOtp">{{ this.sended === false ? 'Send Code OTP' : 'Resend Code In'}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <h5 v-if="sended === true" style="margin-top: 30px" class="btn btn-secondary">{{ this.countdown }}</h5>
        </b-col>
      </b-row>
      <b-button class="btn btn-success " type="submit" @click="topupAction">Save</b-button>
    </b-card-body>
    <b-card-header v-if="this.withdraw === true">
      Withdraw Balance
    </b-card-header>
    <b-card-body v-if="this.withdraw === true">
      <b-form-group id="account"
        label="Account"
        label-for="account">
        <b-form-select id="account"
          :plain="true"
          v-model.lazy.trim="$v.form.account.$model"
          :state="chkState('account')"
          aria-describedby="accountFeedback"
          autocomplete='given-name'
          autofocus
          :options="accountTopup">
        </b-form-select>
        <b-form-invalid-feedback id="accountFeedback">
          <span v-if="!$v.form.account.required">- Tipe pricing tidak boleh kosong!</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="amount"
        label="Amount IDR Top Up"
        label-for="amount">
        <b-form-input id="amount"
          type="number"
          v-model.lazy.trim="$v.form.amount.$model"
          :state="chkState('amount')"
          aria-describedby="amountFeedback"
          placeholder="0"
          autocomplete='given-name'
          autofocus />
        <b-form-invalid-feedback id="amountFeedback" >
          <span v-if="!$v.form.amount.required">- Amount tidak boleh kosong!</span><br>
          <span v-if="!$v.form.amount.numeric">- Amount harus berupa angka valid.</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="account"
        label="Bank"
        label-for="account">
        <b-form-select id="account"
          :plain="true"
          v-model.lazy.trim="$v.form.account.$model"
          :state="chkState('account')"
          aria-describedby="accountFeedback"
          autocomplete='given-name'
          autofocus
          :options="[{value: '', text:'Pilih Bank'},
                    {value: 'fixed', text: 'Fixed'},
                    {value: 'dynamic', text: 'Dynamic'}]">
        </b-form-select>
        <b-form-invalid-feedback id="accountFeedback">
          <span v-if="!$v.form.account.required">- Tipe pricing tidak boleh kosong!</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col sm="12" md="10" lg="10">
          <b-form-group id="otp" label="Insert OTP Code" label-for="otp_code">
            <b-input-group class="mt-3" id="otp Code">
              <b-form-input placeholder="OTP Code" v-model="form.otp"></b-form-input>
              <b-input-group-append>
                <b-button variant="secondary" :disabled="sended" @click="sendOtp">{{ this.sended === false ? 'Send Code OTP' : 'Resend Code In'}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="2" lg="2">
          <h5 v-if="sended === true" style="margin-top: 30px" class="btn btn-secondary">{{ this.countdown }}</h5>
        </b-col>
      </b-row>
      <b-button class="btn btn-success " type="submit" disabled>Save</b-button>
    </b-card-body>

    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <month-range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></month-range-picker>
              <b-input-group-append>
                <b-button variant="success" type="button" @click="exportTable">Export</b-button>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      track-by="id"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="name-slot" slot-scope="prop">
          <router-link :to="onAction('view-user', prop.rowData.users.id, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }} ({{ prop.rowData.users.email }})</router-link>
          <span></span>
        </template>
        <template slot="type-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_type }}</span>
        </template>
        <template slot="name-slot" slot-scope="prop">
          <router-link :to="onAction('view-user', prop.rowData.userId, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }}</router-link>
        </template>
        <template slot="transaction_id-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_id }}</span>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>{{ prop.rowData.amount}} </span>
        </template>
        <template slot="transaction_status-slot" slot-scope="prop">
          <span>{{ prop.rowData.transaction_status.toUpperCase() }} </span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button class="btn btn-success"
              @click="onAction('detail-item', prop.rowData, prop.rowIndex)">
              Detail
            </button>&nbsp;
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import axios from 'axios'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import { validationMixin } from "vuelidate"
  import { required, numeric } from "vuelidate/lib/validators"
  // import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        form: {
            reason: "",
            account: "",
            amount: 0,
            otp: ""
        },
        topup: false,
        withdraw: false,
        countdown: 300,
        sended: false,
        startDate: '',
        endDate: '',
        accountTopup: [],
        balance: 0,
        globalBalance: [],
        first_account: {
          bidr: '',
          btc: '',
          eth: '',
          usdt: '',
          bnb: '',
          tko: '',
          ada: '',
          algo: '',
          doge: '',
          dot: '',
          ftm: '',
          matic: '',
          sol: '',
          xrp: '',
          zil: ''
        },
        second_account: {
          bidr: '',
          btc: '',
          eth: '',
          usdt: '',
          bnb: '',
          tko: '',
          ada: '',
          algo: '',
          doge: '',
          dot: '',
          ftm: '',
          matic: '',
          sol: '',
          xrp: '',
          zil: ''
        },
        cryptoPrice: [],
        user: [],
        errors: {
            message: [],
            status: [],
            code: '',
            headers: [],
            form: {
                reason: [],
            },
        },
        isLoading: false,
        largeModal: false,
        filterText: '',
        message: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `monitor-wallet`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'created_at',
            sortField: 'created_at',
            title: 'Transaction Time'
          },
          {
            name: 'type-slot',
            title: 'Transaction Type'
          },
          {
            name: 'amount-slot',
            title: 'IDR Amount'
          },
          {
            name: 'transaction_status-slot',
            title: 'Transaction Status'
          },
          {
            name: 'name-slot',
            sortField: 'users.name',
            title: 'User'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {
                required
            },
            account: {
              required
            },
            amount: {
              required,
              numeric
            }
        }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/account-topup-command').then((response) => {
        this.accountTopup = response.data.data
        this.form.account = this.accountTopup[0].value
      })

      axios.get(process.env.VUE_APP_SECRET + 'api/v1/tokocrypto/assetinfo').then((res) => {
        let result = res.data.data
        let cryptoPrice = result.crypto
        cryptoPrice.map((v, i) => {
          if (i === 0) {
            this.first_account.bidr = this.formatNumber(v.bidr)
            this.first_account.btc = v.btc
            this.first_account.eth = v.eth
            this.first_account.usdt = v.usdt
            this.first_account.bnb = v.bnb
            this.first_account.tko = v.tko
            this.first_account.ada = v.ada
            this.first_account.algo = v.algo
            this.first_account.doge = v.doge
            this.first_account.dot = v.dot
            this.first_account.ftm = v.ftm
            this.first_account.matic = v.matic
            this.first_account.sol = v.sol
            this.first_account.xrp = v.xrp
            this.first_account.zil = v.zil
          } else {
            this.second_account.bidr = this.formatNumber(v.bidr)
            this.second_account.btc = v.btc
            this.second_account.eth = v.eth
            this.second_account.usdt = v.usdt
            this.second_account.bnb = v.bnb
            this.second_account.tko = v.tko
            this.second_account.ada = v.ada
            this.second_account.algo = v.algo
            this.second_account.doge = v.doge
            this.second_account.dot = v.dot
            this.second_account.ftm = v.ftm
            this.second_account.matic = v.matic
            this.second_account.sol = v.sol
            this.second_account.xrp = v.xrp
            this.second_account.zil = v.zil
          }
        })
        this.balance = result.balance
      })

      this.$http.get('monitor-summary').then((result) => {
        let res = result.data.data
        this.globalBalance = res
      })
    },
    methods: {
      sendOtp() {
        this.sended = true
        this.$http.post(`monitor-wallet-otp`).then((res) => {
          let result = res.data.data

          if (result.length !== 0) {
            this.form.otp = result.code
          }
          this.$swal.fire(
            'Success!',
            'Successfully generated the OTP code',
            'success'
          )
          this.countdownOtp()
        })
      },
      countdownOtp() {
        if(this.countdown > 0) {
          setTimeout(() => {
            this.countdown -= 1
            this.countdownOtp()
          }, 1000)
        } else {
          this.sended = false
          this.countdown = 300
        }
      },
      topupAction() {
        this.validate()
        this.isLoading = true

        let input = new FormData();
        input.append('account', this.form.account)
        input.append('amount', this.form.amount)
        input.append('otp', this.form.otp)
        input.append('transaction_type', 'Top Up')

        this.$http.post('monitor-wallet', input).then((res) => {
          console.log(res)
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Successfully Top Up.',
            'success'
          ).then(() => {
            location.reload()
          })
        }).catch((err) => {
            this.isLoading = false
          if (err.response) {
            if (err.response.status == 400) {
              this.$swal.fire(
                'Failed!',
                err.response.data.meta.message,
                'error'
              )
            }
          }
        })
      },
      openWithdraw () {
        this.topup = false
        this.withdraw = !this.withdraw
      },
      openTopup () {
        this.withdraw = false
        this.topup = !this.topup
      },
      onChangeChecked(id) {
        let arr = this.selected.includes(id)
        if (arr !== true) {
          this.selected.push(id)
        }
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`wallet-monitor/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading =false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      formatNumber (value) {
        return 'Rp.'+accounting.formatNumber(value, 2)
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'view-user') {
          return { name: 'Detail End User', params: { id: btoa(data)} }
        }else if (action == "detail-item") {
          this.$router.push({name: 'Detail Monitor Celengan', params:{id: data.id}})
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      check(user) {
        setInterval(this.$http.post(`check`, {
          bank_id: user.bank.bank_id,
          account_number: user.bank.account_number
        }).then((response) => {
            this.user.bank.owner_name = response.data.data.name
            this.user.bank.account_number = response.data.data.account_number
            this.message = '<h5 style="color:green">' + response.data.meta.message + '</h5>'
        }).catch((error) =>{
          if (error.response) {
            this.message = '<h5 style="color:red">' + error.response.data.meta.message + '</h5>'
          }
        }), 5000)
      },
      chkState(val) {
          const field = this.$v.form[val]
          return !field.$dirty || !field.$invalid
      },
      findFirstError(component = this) {
          if (component.state === false) {
              if (component.$refs.input) {
              component.$refs.input.focus()
              return true
              }
              if (component.$refs.check) {
              component.$refs.check.focus()
              return true
              }
          }
          let focused = false
          component.$children.some((child) => {
              focused = this.findFirstError(child)
              return focused
          })

          return focused
          },
      validate() {
          this.$v.$touch()
          this.$nextTick(() => this.findFirstError())
          return this.isValid
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
.bracket {
  background: #20a8d8;
  padding: 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  width: 100%;
  color: #fff;
  max-width: 500px;
  height: auto;
}

.bracket > h4 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.bracket > h6 {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.bracket-action {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.bracket-padding {
  padding: 4.7rem;
}

.bracket-item {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

.submt {
  width: 100%;
  height: auto;
  display: block;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  footer.modal-footer {
      display: none;
  }

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
